import React, { useState, useEffect } from "react"
import AddBtn from "components/pages/AddBtn"
import InfoCard from "components/pages/InfoCard"
import firebase from "../config/firebase"
import { message } from "antd"
import HeaderSecondary from "components/shared/HeaderSecondary"
import Footer from "components/shared/Footer"
import SidebarTwo from "components/shared/SidebarTwo"

const AdminPanel = () => {
	const [title, setTitle] = useState("Jobs")
	const [btnTitle, setBtnTitle] = useState("Job")
	const [data, setData] = useState([])
	const [value, setValue] = useState("")
	function handleChange(newValue) {
		setValue(newValue)
	}
	useEffect(() => {
		setData([])
		firebase
			.firestore()
			.collection("jobs")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((element) => {
					var singleData = { data: element.data(), id: element.id }
					setData((prevState) => [...prevState, singleData])
				})
			})
			.then(() => {})
			.catch(() => {
				message.info("Error Occured! Can't Fetch the Record")
			})
	}, [value])
	return (
		<>
			<HeaderSecondary />
			<div className="row m-0 forFlex" style={{ minHeight: "90vh" }}>
				<div
					className={
						false ? "col-lg-2 col-md-3 col-sm-4 m-0 p-0 height-full" : "col-lg-2 col-md-3 col-sm-4 m-0 p-0 height-0"
					}
				>
					<SidebarTwo />
				</div>
				<div className="col-lg-10 col-md-9 col-sm-8 mt-4 p-0 bg-white" style={{ zIndex: "-100" }}>
					<div>
						<AddBtn title={title} btnTitle={btnTitle} value={value} onChange={handleChange} />
						{data.map((element, index) => {
							return (
								<InfoCard
									key={index}
									jobId={element.data.jobId}
									jobTitle={element.data.jobTitle}
									jobExperience={element.data.jobExperience}
									jobDescription={element.data.jobDescription}
									jobKeySkills={element.data.jobKeySkills}
									jobLocation={element.data.jobLocation}
									recordId={element.id}
									value={value}
									onChange={handleChange}
								/>
							)
						})}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default AdminPanel
