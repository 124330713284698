// extracted by mini-css-extract-plugin
export var icBar = "styles-module--icBar--0ttCY";
export var icBarDescTxt = "styles-module--icBarDescTxt--Txd27";
export var icBarHead = "styles-module--icBarHead--vBbr1";
export var icBarTxt = "styles-module--icBarTxt--VQJrJ";
export var icBtns = "styles-module--icBtns--1NiJH";
export var icDeleteBtn = "styles-module--icDeleteBtn--fEny1";
export var icDescHeading = "styles-module--icDescHeading--sjcoQ";
export var icExp = "styles-module--icExp--f9Ru5";
export var icListItem = "styles-module--icListItem--JhfoU";
export var icListMain = "styles-module--icListMain--ZYtQ2";
export var icMain = "styles-module--icMain--E5zA1";
export var icTitle = "styles-module--icTitle--vsHUm";
export var icUpdateBtn = "styles-module--icUpdateBtn--dMH6F";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";